import React from "react";
import { Helmet } from "react-helmet";

const IePage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="noindex, nofollow, noimageindex"
          data-no-index="true"
        />
      </Helmet>
      <section
        className="cmscontent w-full h-full"
        style={{ backgroundColor: "black" }}
      >
        <div className="container mx-auto">
          <div
            style={{
              backgroundImage: `url(/images/browser.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "400px",
              maxWidth: "2048px",
            }}
          >
          </div>
          <div
            style={{
              gridArea: "1 / 1",
              position: "absolute",
              top: "150px",
              placeItems: "center",
              display: "grid",
            }}
            className="transition-all duration-75 transform -translate-y-60 flex justify-start container mx-auto"
          >
            <div style={{ backgroundColor: "grey", opacity: "0.8" }}>
              <div className="py-1 px-9">
                <h1 className="text-left text-2xl md:text-4xl lg:text-4xl font-extralight tracking-wider text-white leading-tight">
                  Hello and welcome
                </h1>
                <p className="text-left text-sm md:text-base lg:text-lg font-extralight tracking-wider text-white leading-snug">
                  It looks like you are using a prehistoric browser!{" "}
                </p>
              </div>
            </div>
          </div>

          <article
            className="container mx-auto text-left py-12 px-6 md:px-12"
            style={{ backgroundColor: "grey" }}
          >
            <p className="text-xl md:text-4xl" style={{ color: "white" }}>
              It looks like you are using a prehistoric browser! maybe Internet
              Explorer! (Oh No) We do not support ancient browsers, please
              update to a modern browser to use our website...
            </p>
            <br />
            <p className="text-xl md:text-4xl" style={{ color: "white" }}>
              If you are using Internet Explorer, you should update to
              <a
                href="https://www.microsoft.com/en-us/edge"
                alt="Microsoft Edge"
                target="_blank"
                rel="noreferrer"
                style={{ color: "lightblue" }}
              >
                {" "}
                Microsoft Edge
              </a>
              . You could consider using
              <a
                href="https://www.google.com/chrome/"
                alt="Google Chrome"
                target="_blank"
                rel="noreferrer"
                style={{ color: "lightblue" }}
              >
                {" "}
                Chrome
              </a>
              ,
              <a
                href="https://www.mozilla.org/en-GB/firefox/new/"
                alt="Google Chrome"
                target="_blank"
                rel="noreferrer"
                style={{ color: "lightblue" }}
              >
                Firefox{" "}
              </a>
              or
              <a
                href="https://www.opera.com/"
                alt="Google Chrome"
                target="_blank"
                rel="noreferrer"
                style={{ color: "lightblue" }}
              >
                {" "}
                Opera{" "}
              </a>
              on windows for a better browsing experience. If you are using
              <a
                href="https://support.apple.com/en_GB/downloads/safari"
                alt="Google Chrome"
                target="_blank"
                rel="noreferrer"
                style={{ color: "lightblue" }}
              >
                {" "}
                Safari{" "}
              </a>
              on a Mac, make sure you are using an updated version.
            </p>
          </article>
        </div>
      </section>
    </>
  );
};

export default IePage;
